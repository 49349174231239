import { Import } from '@/utilities/import.utils';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Homepage = Import({
  touch: React.lazy(() => import('../modules/homepage')),
  desktop: React.lazy(() => import('../modules/homepage'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;

const elements = [
  {
    Component: Homepage,
    path: '/'
  }
];

export const RouterProvider = () => {
  return (
    <BrowserRouter>
      {/* <Header /> */}
      <Routes>
        {elements.map(({ Component, path }, idx) => (
          <Route
            key={idx}
            element={
              <React.Suspense fallback={null}>
                <Component />
              </React.Suspense>
            }
            path={path}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};
